import { Link } from "react-router-dom";
import "./Navbar.css";
const Navbar = ()=>{
    return <div style={{width:"100vw"}}>
        <div style={{display:"flex",justifyContent:"space-between",color:"#fff"}}>
            <div style={{fontSize:"36px",fontWeight:"800",padding:"16px",paddingLeft:"48px",color:"#fff"}}><Link to="/" style={{color:"#fff",textDecoration:"none",userSelect:"none"}}>Post-it</Link></div>
            <div className="rightactions" style={{fontSize:"24px",fontWeight:"800",padding:"16px",marginRight:"64px",display:"flex",placeContent:"center",color:"#00565B",cursor:"pointer",userSelect:"none"}}>
            <button><Link to="/posts">Posts</Link></button>
            <button><a href="https://pushpendrahpx.github.io" >About Us</a></button>
            </div>
        </div>
    </div>
}
export default Navbar