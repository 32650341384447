
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Posts from './components/posts';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<>
              <Navbar />
              <Home />
            </>} />
          <Route path="/posts" exact element={<>
              <Navbar />
              <Posts />
            </>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
