import { useState,useEffect } from "react"
import "./posts.css"
const Posts = ()=>{
    let [postState,setPostState] = useState([])

    useEffect(async () => {
        
        try{
            let re = await fetch("https://workerapp.wisarts-cdn.workers.dev/api/posts");
            let d = await re.json();
            if(re.ok){
                d.reverse()
                setPostState(d);
            }else{
                setPostState([]);
            }
        }catch(e){
            
        }
       
    }, [])

    return <div style={{maxWidth:"1080px",margin:"0 auto",textAlign:"center"}}>
    <div style={{fontSize:"96px",fontWeight:"800",color:"#fff",textAlign:"center"}}> Some special moments </div>


   
    <div style={{display:"flex",flexWrap:"wrap"}}>
       {postState.length == 0 ? 
       <>
       </>
       :
       <>
        {postState.map((eachPost,index)=>{
            return  (<div key={index} style={{width:"48%",border:"2px solid #00565B",background:"#fff",borderRadius:"20px",marginTop:"60px"}}>

                <div style={{width:"100%",border:"2px solid #00565B",background:"#fff",borderRadius:"20px"}}>
                

                    <div style={{display:"grid",padding:"10px",paddingLeft:'20px',paddingTop:"20px"}}>
                        <input className="titleInput" type="text" value={eachPost.title} name="title" disabled={true} />
                    </div>
                    <hr style={{width:"95%",borderColor:"#00565B"}} />
                    <div style={{display:"grid",padding:"5px",paddingLeft:'15px'}}>
                        {eachPost.content}
                    </div>
                    <hr style={{width:"95%",borderColor:"#00565B"}} />
                    <div style={{width:"100%",display:'flex',justifyContent:"center"}}>
                        <input className="titleInput" type="text" value={new Date(eachPost.timeStamp)} name="title" disabled={true} style={{fontSize:"18px"}} />
                    </div>
                
                </div>



        </div>);
        })}
    
       </>
}
    </div>
</div>
}
export default Posts