import { useState } from "react";
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import "./Home.css"
const Home = ()=>{

    
    let [formState,setFormState] = useState({
        title:"Type your Title ...",
        content:"Express your moments here ..."
    })
    let [status,setStatus] = useState({
        isSubmit:false,
        isLoading:false,
        isChange:false
    })
   

    let submitHandle = async ()=>{
        if(status.isChange == false){
            alert("Please Edit Post Details!");
            return;
        }
        
        try{


        setStatus({isSubmit:false,isLoading:true});
        let body = {
            title:formState.title,
            content:formState.content,
            timeStamp:(new Date()).getTime()
        }
        let response = await fetch("https://workerapp.wisarts-cdn.workers.dev/api/posts",{
            method:"POST",
            headers:{
                "Content-type":"application/json"
            },
            body:JSON.stringify(body)
        })
        //    let reader = response.getReader();

            console.log(await response)
            if(response.ok){
                alert(" Posted! ")
                setFormState({
                    title:"Type Title here ...",
                    content:"Express your moments here ..."
                })
                setStatus({isSubmit:true,isLoading:false});
            }else{

                alert(" Failed ")
                setStatus({isSubmit:true,isLoading:false});
            }
            
        }catch(e){
            alert(" Failed ")
            
            setStatus({isSubmit:false,isLoading:false});
            console.log(e)
        }
    }
    let inputHandle = async (e)=>{
        setFormState(prev=>{
            return {...prev,[e.target.name]:e.target.value}
        })
        setStatus(prev=>{
            return {...prev,isChange:true}
        })
    }


    return <div style={{maxWidth:"720px",margin:"0 auto"}}>
        <div style={{fontSize:"96px",fontWeight:"800",color:"#fff"}}>Share your moment</div>


        <div style={{width:"100%",border:"2px solid #00565B",background:"#fff",borderRadius:"20px",marginTop:"60px"}}>
            

            <div style={{display:"grid",padding:"10px",paddingLeft:'20px',paddingTop:"20px"}}>
                <input className="titleInput" type="text" defaultValue={formState.title} name="title" onChange={inputHandle} />
            </div>
            <hr style={{width:"95%",borderColor:"#00565B"}} />
            <div style={{display:"grid",padding:"5px",paddingLeft:'15px'}}>
                <textarea  className="titleInput"  defaultValue={formState.content} style={{maxHeight:"320px"}} name="content" onChange={inputHandle} />
            </div>
            <hr style={{width:"95%",borderColor:"#00565B"}} />
            <div style={{width:"100%",display:'flex',justifyContent:"end"}}>
                <button  className="buttonRight"  style={{marginRight:'10px',marginBottom:"10px"}} onClick={submitHandle}> 
                    Share
                </button>
            </div>
            
            {status.isLoading == true ? <LinearProgress style={{width:"95%",margin:"0 auto"}} /> : ''}
            
        </div>
    </div>
}
export default Home